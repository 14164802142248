// React & Next
import { createElement } from 'react';

// App - Types
import type { SecurityFrameworkSource } from '@/types/security-framework';
import type { IconProps } from '@/components/atoms/icon';

// App - Other
import {
  AzureIcon,
  FedRampIcon,
  ColorableAwsIcon,
  CisIcon,
  ColorableCloverLogoIcon,
  NistIcon,
  HitrustIcon,
  OwaspIcon,
  PciIcon,
  IecIcon,
  UsersIcon,
} from '@/components/atoms/icon';
import { translateSecurityFrameworkSource } from './security-framework-source';

type SecurityFrameworkSourceIconProps = Partial<IconProps> & {
  source: SecurityFrameworkSource;
};

export const SecurityFrameworkSourceIcon = ({
  source,
  ...iconProps
}: SecurityFrameworkSourceIconProps) => {
  return createElement(translateSecurityFrameworkSourceToIcon(source), {
    'aria-label': translateSecurityFrameworkSource(source),
    ...iconProps,
  });
};

export const translateSecurityFrameworkSourceToIcon = (source: SecurityFrameworkSource) => {
  if (source === 'azure') return AzureIcon;

  if (source === 'fed_ramp') return FedRampIcon;

  if (source === 'owasp') return OwaspIcon;

  if (source === 'nist') return NistIcon;

  if (source === 'hitrust') return HitrustIcon;

  if (source === 'iec') return IecIcon;

  if (source === 'aws') return ColorableAwsIcon;

  if (source === 'clover') return ColorableCloverLogoIcon;

  if (source === 'pci') return PciIcon;

  if (source === 'cis') return CisIcon;

  if (source === 'custom') return UsersIcon;

  return UsersIcon;
};
