// 3rd
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { RequirementStatusGroups } from '@/types/story/requirements-analysis';
import type { RequirementStoryOccurrencesOverview } from '../../types/requirement-story-occurrence';
import { ZodRequirementDto, castRequirementDtoToRequirement } from './dtos/requirement';
import {
  ZodRequirementStoryOccurrenceDto,
  castRequirementStoryOccurrenceDtoToRequirementStoryOccurrence,
} from './dtos/requirement-story-occurrence';
import {
  castCodeFileRequirementCoverageDtoToCodeFileRequirementCoverage,
  ZodCodeFileRequirementCoverageDto,
} from './dtos/requirement-code-file-coverage';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { REQUIREMENT_QUERY_KEYS } from '../../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  applicationId: z.string().optional(),
  businessUnit: z.string().optional(),
  requirementId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityRequirementOverview: z.object({
    codeFileRequirementCoverages: z.array(ZodCodeFileRequirementCoverageDto).optional(),
    storyOccurrences: z.array(ZodRequirementStoryOccurrenceDto).optional(),
    securityRequirement: ZodRequirementDto,
  }),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getRequirementStoryOccurrencesOverview = async (
  requirementId: string,
  applicationId?: string,
  businessUnit?: string
): Promise<RequirementStoryOccurrencesOverview> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      applicationId ? { businessUnit, applicationId, requirementId } : { requirementId }
    );
    const res = await apiClient.post(`/SecurityFramework/GetSecurityRequirementOverview`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    const requirement = castRequirementDtoToRequirement(
      parsedRes.securityRequirementOverview.securityRequirement
    );

    const storiesOccurrences = (parsedRes.securityRequirementOverview.storyOccurrences || []).map(
      castRequirementStoryOccurrenceDtoToRequirementStoryOccurrence
    );

    const sentToDevInStories = storiesOccurrences.filter((story) =>
      RequirementStatusGroups.sent_to_dev.includes(story.requirementStatus)
    );

    const coveredInStories = storiesOccurrences.filter((story) =>
      RequirementStatusGroups.covered.includes(story.requirementStatus)
    );

    const rejectedInStories = storiesOccurrences.filter((story) =>
      RequirementStatusGroups.irrelevant.includes(story.requirementStatus)
    );

    const codeFileRequirementCoverages = (
      parsedRes.securityRequirementOverview.codeFileRequirementCoverages || []
    ).map(castCodeFileRequirementCoverageDtoToCodeFileRequirementCoverage);

    return {
      ...requirement,
      requestedInStories: storiesOccurrences,
      sentToDevInStories,
      coveredInStories,
      rejectedInStories,
      codeFileRequirementCoverages,
    };
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseRequirementStoryOccurrencesOverview = {
  requirementId?: string;
  applicationId?: string;
  businessUnit?: string;
  enabled?: boolean;
};

export const useRequirementStoryOccurrencesOverview = ({
  requirementId,
  applicationId,
  businessUnit,
  enabled = true,
}: UseRequirementStoryOccurrencesOverview) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: REQUIREMENT_QUERY_KEYS.requirementStoryOccurrencesOverview(
      requirementId!,
      applicationId,
      businessUnit
    ),
    queryFn: async () =>
      await getRequirementStoryOccurrencesOverview(requirementId!, applicationId, businessUnit),
    enabled: enabled && !!requirementId,
  });

  return {
    requirementStoryOccurrencesOverview: data,
    isLoadingRequirementStoryOccurrencesOverview: isLoading,
    isFetchingRequirementStoryOccurrencesOverview: isFetching,
    didFetchRequirementStoryOccurrencesOverviewErrored: isLoadingError,
  };
};
