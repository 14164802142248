// React & Next
import type { MouseEvent } from 'react';

// 3rd
import { Highlight, themes } from 'prism-react-renderer';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Code,
  Flex,
  Skeleton,
  useDisclosure,
  useToken,
} from '@chakra-ui/react';

// App - Types
import type { KnowledgeSource } from '@/types/integration';
import type { RequirementCountermeasure } from '../../../types/requirement-countermeasure';

// App - Other
import Locale from '@/locale/en.json';
import { Text } from '@/components/atoms/typography';
import { Tag } from '@/components/molecules/tag';
import { Tooltip } from '@/components/molecules/tooltips';
import { KnowledgeSourceIcon } from '@/components/translators/integration';
import { translateApplicationProgrammingLanguageExtension } from '@/components/translators/application';
import {
  translateRequirementCountermeasureSnippetType,
  translateRequirementCountermeasureSource,
  // translateRequirementCountermeasureStatus,
  // translateRequirementCountermeasureStatusToColor,
} from '@/components/translators/security-framework/requirement';

const locale = Locale.features.frameworks['frameworks-requirement-countermeasure-form'];

type RequirementCountermeasureAccordionProps = {
  countermeasure: RequirementCountermeasure;
};

export const RequirementCountermeasureAccordion = ({
  countermeasure,
}: RequirementCountermeasureAccordionProps) => {
  const [defaultOutlineColor] = useToken('colors', ['border.primary']);
  const { isOpen: isCountermeasureExpanded, onToggle: toggleCountermeasure } = useDisclosure({
    defaultIsOpen: false,
  });

  const handleToggleAccordion = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    toggleCountermeasure();
  };

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      height="fit-content"
      outline={`1px solid ${defaultOutlineColor}`}
      borderRadius="sm"
      transition="150ms all ease-in-out"
    >
      <Accordion variant="old" allowMultiple index={isCountermeasureExpanded ? [0] : []} w="100%">
        <AccordionItem bg="transparent" w="100%" gap="none">
          <Flex
            alignSelf="stretch"
            alignItems="center"
            gap="sm"
            px="sm"
            py="6px"
            borderBottom={isCountermeasureExpanded ? '1px' : undefined}
            borderColor={isCountermeasureExpanded ? 'border.primary' : undefined}
            transition="150ms all ease-in-out"
            onClick={handleToggleAccordion}
          >
            <Flex alignItems="center" justifyContent="center" h="30px" w="20px">
              <AccordionButton
                justifyContent="center"
                h="20px"
                w="20px"
                borderRadius="full"
                p="xs"
                transform={isCountermeasureExpanded ? 'rotate(180deg)' : 'rotate(-90deg)'}
              >
                <AccordionIcon />
              </AccordionButton>
            </Flex>

            <Flex alignItems="center" gap="sm" w="100%">
              {/*<Tag*/}
              {/*  size="old-sm"*/}
              {/*  px="6px"*/}
              {/*  bg={translateRequirementCountermeasureStatusToColor(countermeasure.status)}*/}
              {/*  color="text.invert-primary"*/}
              {/*>*/}
              {/*  {translateRequirementCountermeasureStatus(countermeasure.status)}*/}
              {/*</Tag>*/}

              <Tag
                size="old-sm"
                px="6px"
                bg="surface.primary"
                border="1px"
                borderColor="border.primary"
              >
                {translateRequirementCountermeasureSnippetType(countermeasure.snippetType)}
              </Tag>

              <Text variant="detail" color="text.high-tone">
                {countermeasure.title}
              </Text>

              {countermeasure.source !== 'manual' || countermeasure.codeDetails ? (
                <Flex grow={1} />
              ) : null}

              {countermeasure.source !== 'manual' ? (
                <Tag
                  size="old-sm"
                  variant="secondary"
                  px="6px"
                  display="flex"
                  alignItems="center"
                  gap="4px"
                  whiteSpace="nowrap"
                >
                  {translateRequirementCountermeasureSource(countermeasure.source)}
                </Tag>
              ) : null}

              {countermeasure.codeDetails ? (
                <Tooltip content={locale['See origin']}>
                  <Tag
                    size="old-sm"
                    px="6px"
                    bg="transparent"
                    border="1px"
                    borderColor="border.primary"
                    transition="all 180ms ease-in-out"
                    _hover={{
                      cursor: 'pointer',
                      bg: 'surface.secondary',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      window.open(countermeasure.codeDetails!.originUrl, '_blank');
                    }}
                  >
                    <KnowledgeSourceIcon
                      source={countermeasure.codeDetails.codeSourceType as KnowledgeSource}
                    />
                  </Tag>
                </Tooltip>
              ) : null}
            </Flex>
          </Flex>

          <AccordionPanel
            px="12px !important"
            py="12px !important"
            motionProps={{
              style: {
                width: '100%',
              },
            }}
          >
            <Flex w="100%">
              {['code', 'configuration'].includes(countermeasure.snippetType) ? (
                <Highlight
                  language={translateApplicationProgrammingLanguageExtension(
                    countermeasure.codeDetails?.programmingLanguage || 'typescript'
                  )}
                  code={countermeasure.content}
                  theme={themes.github}
                >
                  {({ tokens, getLineProps, getTokenProps }) => (
                    <Code
                      padding={2}
                      rounded="sm"
                      py="none"
                      px="none"
                      display="block"
                      whiteSpace="pre"
                      bg="transparent"
                      overflow="auto"
                      position="relative"
                      w="100%"
                    >
                      {tokens.map((line, i) => (
                        <div key={i} {...getLineProps({ line })}>
                          {line.map((token, key) => (
                            <span key={key} {...getTokenProps({ token })} />
                          ))}
                        </div>
                      ))}
                    </Code>
                  )}
                </Highlight>
              ) : (
                <Text variant="detail" color="text.high-tone">
                  {countermeasure.content}
                </Text>
              )}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};

const Loading = () => {
  const [defaultOutlineColor] = useToken('colors', ['border.primary']);

  return (
    <Flex
      alignItems="flex-start"
      gap="lg"
      height="fit-content"
      outline={`1px solid ${defaultOutlineColor}`}
      borderRadius="sm"
      py="sm"
      ps="md"
      transition="150ms all ease-in-out"
    >
      <Skeleton w="180px" h="22px" />

      <Skeleton w="55px" h="22px" />
    </Flex>
  );
};

RequirementCountermeasureAccordion.Loading = Loading;
