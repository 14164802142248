// React & Next
import { useCallback, useEffect, useMemo } from 'react';
import type { ReactNode } from 'react';

// 3rd
import { createFilter } from 'react-select';
import { Skeleton, forwardRef } from '@chakra-ui/react';
import type { ChakraComponent, StyleProps } from '@chakra-ui/react';

// App - Types
import type { InputProps, ChakraStylesConfig } from '@/components/molecules/form';
import type { SecurityFramework } from '../../types/security-framework';

// App - Other
import Locale from '@/locale/en.json';
import { Select } from '@/components/molecules/form';

const locale = Locale.features.frameworks['frameworks-select-requirements-framework'];

type SelectSecurityFrameworkComponent = ChakraComponent<'div', SelectSecurityFrameworkProps> & {
  Loading: typeof Loading;
};

type MandatorySecurityFrameworkFields = Pick<SecurityFramework, 'id' | 'name'>;

type SelectSecurityFrameworkProps = StyleProps &
  Pick<InputProps, 'variant' | 'size'> & {
    frameworks: MandatorySecurityFrameworkFields[];
    includeDefaultOption?: boolean | string;
    placeholder?: string;
    selected?: MandatorySecurityFrameworkFields | MandatorySecurityFrameworkFields[];
    onChange: (
      selection?: MandatorySecurityFrameworkFields | MandatorySecurityFrameworkFields[]
    ) => void;
    onBlur?: () => void;
    isDisabled?: boolean;
    isMulti?: boolean;
    isSearchable?: boolean;
    formatOptionLabel?: (option: string) => ReactNode;
    chakraStyles?: ChakraStylesConfig<{ label: string; value: string }>;
    selectedOptionColor?: string;
  };

export const SelectSecurityFramework = forwardRef(
  (
    {
      variant,
      size,
      frameworks,
      includeDefaultOption,
      placeholder,
      selected,
      onChange,
      onBlur,
      isDisabled,
      isMulti,
      isSearchable,
      formatOptionLabel,
      chakraStyles,
      selectedOptionColor,
      ...props
    }: SelectSecurityFrameworkProps,
    ref
  ) => {
    const defaultOption = useMemo(
      () => ({
        value: '',
        label: typeof includeDefaultOption === 'string' ? includeDefaultOption : locale['All'],
      }),
      [includeDefaultOption]
    );

    const options = useMemo(() => {
      const frameworkOptions = frameworks
        .map((framework) => ({
          value: framework.id,
          label: framework.name,
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
          })
        );

      if (!!includeDefaultOption && frameworkOptions.length > 1)
        return [defaultOption, ...frameworkOptions];

      return frameworkOptions;
    }, [defaultOption, frameworks, includeDefaultOption]);

    const currentSelection = useMemo(() => {
      if (selected && Array.isArray(selected)) {
        return selected.map((framework) => ({
          value: framework.id,
          label: framework.name,
        }));
      } else if (selected) {
        return {
          value: selected.id,
          label: selected.name,
        };
      }

      const hasDefaultOption = options.some((option) => option.value === defaultOption.value);

      if (hasDefaultOption) {
        return defaultOption;
      }

      return undefined;
    }, [defaultOption, options, selected]);

    const getCurrentSelection = useCallback(
      (optionValue: string) => {
        return frameworks.find((framework) => framework.id === optionValue);
      },
      [frameworks]
    );

    useEffect(() => {
      if (!currentSelection && options.length === 1) {
        if (isMulti) {
          onChange([getCurrentSelection(options[0].value)] as MandatorySecurityFrameworkFields[]);
        } else {
          onChange(getCurrentSelection(options[0].value) as MandatorySecurityFrameworkFields);
        }
      }
    }, [onChange, options, currentSelection, isMulti, getCurrentSelection]);

    return (
      <Select
        useBasicStyles
        isMulti={isMulti}
        variant={variant || 'old.outline'}
        size={(size as 'sm' | 'md' | 'lg') || 'sm'}
        options={options}
        value={currentSelection}
        placeholder={placeholder}
        selectedOptionColor={selectedOptionColor || 'surface.brand.primary'}
        onChange={(selectedOptions) => {
          if (!selectedOptions) {
            return onChange();
          }

          if (isMulti && Array.isArray(selectedOptions)) {
            return onChange(
              selectedOptions.map(
                (option) => getCurrentSelection(option.value) as MandatorySecurityFrameworkFields
              )
            );
          } else if (selectedOptions) {
            const selectedOption = getCurrentSelection(
              (selectedOptions as { value: string }).value
            );

            return onChange(selectedOption);
          } else {
            return onChange();
          }
        }}
        onBlur={onBlur}
        isDisabled={isDisabled}
        ref={ref}
        isSearchable={!!isSearchable}
        filterOption={createFilter({
          stringify: (option) => option.label,
        })}
        formatOptionLabel={(option) =>
          formatOptionLabel ? formatOptionLabel(option.label) : option.label
        }
        chakraStyles={
          chakraStyles || {
            container: (styles) => ({
              maxW: '100%',
              w: 'fit-content',
              minW: '165px',

              ...styles,
              ...props,
            }),
            menuList: (styles) => ({
              ...styles,
              ...props,
            }),
          }
        }
      />
    );
  }
) as SelectSecurityFrameworkComponent;

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

SelectSecurityFramework.Loading = Loading;
