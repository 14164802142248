// 3rd
import { z } from 'zod';

// App - Types
import type { CodeSource } from '@/types/integration';
import { castKnowledgeSourceDtoToKnowledgeSource, CodeSourcesDto } from '@/types/integration/dtos';
import {
  ApplicationProgrammingLanguagesDto,
  castApplicationProgrammingLanguageDtoToApplicationProgrammingLanguage,
} from '@/types/application/dtos';
import {
  RequirementCountermeasureSourcesDto,
  RequirementCountermeasureSnippetTypesDto,
  RequirementCountermeasureStatusesDto,
  castRequirementCountermeasureSourceDtoToRequirementCountermeasureSource,
  castRequirementCountermeasureSnippetTypeDtoToRequirementCountermeasureSnippetType,
  castRequirementCountermeasureStatusDtoToRequirementCountermeasureStatus,
} from '@/types/security-framework/requirement/dtos';
import type {
  RequirementCountermeasure,
  RequirementCountermeasureCodeDetails,
} from '../../../types/requirement-countermeasure';

export const ZodRequirementCountermeasureCodeDetailsDto = z.object({
  codeSourceType: z.enum(CodeSourcesDto),
  confidence: z.number(),
  originUrl: z.string(),
  programmingLanguage: z.enum(ApplicationProgrammingLanguagesDto),
  reasoning: z.string(),
  repositoryId: z.string(),
});

export type RequirementCountermeasureCodeDetailsDto = z.infer<
  typeof ZodRequirementCountermeasureCodeDetailsDto
>;

export const castRequirementCountermeasureCodeDetailsDtoToRequirementCountermeasureCodeDetails = (
  dto: RequirementCountermeasureCodeDetailsDto
): RequirementCountermeasureCodeDetails => {
  return {
    codeSourceType: castKnowledgeSourceDtoToKnowledgeSource(dto.codeSourceType) as CodeSource,
    confidence: dto.confidence,
    originUrl: dto.originUrl,
    programmingLanguage: castApplicationProgrammingLanguageDtoToApplicationProgrammingLanguage(
      dto.programmingLanguage
    ),
    reasoning: dto.reasoning,
    repositoryId: dto.repositoryId,
  };
};

export const ZodRequirementCountermeasureDto = z.object({
  codeDetails: ZodRequirementCountermeasureCodeDetailsDto.optional(),
  content: z.string(),
  id: z.string(),
  securityRequirementIds: z.string().array(),
  title: z.string(),
  source: z.enum(RequirementCountermeasureSourcesDto),
  status: z.enum(RequirementCountermeasureStatusesDto),
  snippetType: z.enum(RequirementCountermeasureSnippetTypesDto),
});

export type RequirementCountermeasureDto = z.infer<typeof ZodRequirementCountermeasureDto>;

export const castRequirementCountermeasureDtoToRequirementCountermeasure = (
  dto: RequirementCountermeasureDto
): RequirementCountermeasure => {
  return {
    id: dto.id,
    content: dto.content,
    codeDetails: dto.codeDetails
      ? castRequirementCountermeasureCodeDetailsDtoToRequirementCountermeasureCodeDetails(
          dto.codeDetails
        )
      : undefined,
    status: castRequirementCountermeasureStatusDtoToRequirementCountermeasureStatus(dto.status),
    source: castRequirementCountermeasureSourceDtoToRequirementCountermeasureSource(dto.source),
    snippetType: castRequirementCountermeasureSnippetTypeDtoToRequirementCountermeasureSnippetType(
      dto.snippetType
    ),
    title: dto.title,
  };
};
